import styled from "styled-components";
import Checkbox from "./Checkbox";

const CheckboxContainer = styled.div`
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0px;
    }
`;
const ComponentContainer = styled.div`
    margin-top: 10px;
    margin-left: 30px;

    label {
        opacity: 1;
    }
`;

const Radio = ({
    value = "1",
    onChange = () => console.error("checkbox onChange not defined"),
    options = [
        { label: "test 1", value: "1" },
        { label: "test 2", value: "2" },
        { label: "test 3", value: "3" },
    ],
}) => (
    <div>
        {options.map((option, index) => (
            <CheckboxContainer key={index}>
                <Checkbox
                    key={value}
                    value={option.value === value}
                    label={option.label}
                    onChange={(e) => onChange(option.value)}
                />
                {!!option.Component && value === option.value && (
                    <ComponentContainer>{option.Component}</ComponentContainer>
                )}
            </CheckboxContainer>
        ))}
    </div>
);

export default Radio;
