import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import {
    GoogleReCaptcha, GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import styled from "styled-components";
import * as yup from "yup";
import Button from "../../components/Buttons/Button";
import FlexBox from "../../components/FlexBox/FlexBox";
import NextArrowIcon from "../../components/Icons/components/NextArrowIcon";
import Checkbox from "../../components/Inputs/Checkbox";
import TextInput from "../../components/Inputs/TextInput";
import Loader from "../../components/Loader/Loader";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import { selectMobile } from "../../features/appSlice";
import {
    selectAdditionalProducts, selectIsCompany, selectMainProduct, selectMinimalMonthsParsed,
    selectOrderProducts, selectServicesBefore,
    selectServicesDuring, email
} from "../../features/basketSlice";
import OrderService from "../../services/OrderService";
import { setTransaction } from "../../features/transactionSlice";
import { setEmail } from "../../features/basketSlice";




const EmailRow = styled.div`
    /* padding: 35px 25px; */
    input {
        margin-top: 18px;
        width: 100%;
    }
`;
const Footer = styled.div`
    gap: 10px;
    display: flex;
    

    a {
        flex: 1;
        margin-left: 10px;
    }
`;

const ReCaptchaContainer = styled.div`
    position: relative;
    z-index: 100;
`;

const RequiredIcon = styled.span`
    color: ${({ theme }) => theme.danger};
    margin: 0 2px;
`;

const schema = yup.object().shape({
    email: yup.string().email().required(),
});

const EmailConfirm = ({ isDisableButton }) => {

    const dispatch = useDispatch();
    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        watch
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });

    const agreementWatcher = watch('agreement')
    const emailWatcher = watch('email')

    const [token, setToken] = useState();
    const orderProducts = useSelector(selectOrderProducts);
    const minimal_month = useSelector(selectMinimalMonthsParsed);
    const isCompany = useSelector(selectIsCompany);
    const emailAdress = useSelector(email);

    const isSharedBasket = useSelector(state => state.basket.isSharedBasket)





    const queryClient = useQueryClient();
    const history = useHistory();

    const { mutate, isLoading } = useMutation(OrderService.walkAroundEmail, {
        onSuccess: (data) => {
            dispatch(setTransaction(data.data));
            history.push("/rent/contract-data")
        },
    });

    const mainProduct = useSelector(selectMainProduct);
    const minimal_months = useSelector(selectMinimalMonthsParsed);
    const additionalProducts = useSelector(selectAdditionalProducts);
    const servicesBefore = useSelector(selectServicesBefore);
    const servicesDuring = useSelector(selectServicesDuring);

    const parseList = ({ id }) => ({ id, quantity: 1 });

    const query = {
        main_product_id: mainProduct.id,
        minimal_months,
        real_months: minimal_months,
        additional_products: additionalProducts.map(parseList),
        services_before: servicesBefore.map(parseList),
        services_during: servicesDuring.map(parseList),
        isCompany,
    };

    let summaryData;

    const newCalculation = () => {
        if (!summaryData) return {};
        if (!!isCompany) {
            return summaryData.company_option.option_raw;
        } else {
            return summaryData.natural_person_option.option_raw;
        }
    }


    const onSubmit = (values) => {
        summaryData = queryClient.getQueryData(["summary", query]);
        dispatch(setEmail(values.email));
        const {
            main_items,
            accessories_items,
            services_before_items,
            services_during_items,
        } = orderProducts;

        const {
            gross_month_cost,
            gross_optional_main_item_repurchase,
            net_administrative_fee,
            net_month_cost,
            total_gross_price,
        } = newCalculation();

        const newData = {
            c16: values.email,
            c39: values.agreement,
            recaptcha: token,
            products: {
                main_items,
                accessories_items,
                services_before_items,
                services_during_items,
            },
            rent_params: {
                net_month_cost,
                gross_month_cost,
                net_administrative_fee,
                months: minimal_month,
                total_gross_price,
                gross_optional_main_item_repurchase,
                is_company: isCompany,
            },
        }
        mutate(newData);
    };

    const isMobile = useSelector(selectMobile);
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
        >
            {isLoading && <Loader fixed />}
            <Form onSubmit={handleSubmit(onSubmit)}>
                <EmailRow>
                    <SectionHeader marginBottom={12}>
                        Podaj adres email
                    </SectionHeader>
                    <p style={{ fontSize: "17px" }}>
                        aby {isSharedBasket ? '' : 'otrzymać szczegóły oferty i'} wypełnić dane do umowy
                        najmu
                    </p>

                    <TextInput
                        defaultValue={emailAdress ? emailAdress : ''}
                        placeholder="Twój email"
                        register={register("email")}
                    />
                    <FlexBox
                        textAlign="initial"
                        margin="1rem 0 0 0"
                    >

                        <Controller
                            name="agreement"
                            control={control}
                            defaultValue={emailAdress ? true : false}
                            render={({
                                field: { onChange, value },
                            }) => (
                                <Checkbox
                                    requiredIcon
                                    smallLabel
                                    label="Wyrażam zgodę na przetwarzanie przez Univibe Sp. z o.o. (ul. Sarmacka 1/19, 02-972 Warszawa), moich danych osobowych w postaci adresu email w celu przesyłania mi za pomocą środków komunikacji elektronicznej informacji handlowych dotyczących produktów i usług oferowanych przez tę Spółkę."
                                    value={value}
                                    onChange={(e) => onChange(e)}
                                ></Checkbox>
                            )}
                        />
                    </FlexBox>
                    <FlexBox margin="1rem 0 0 0">
                        <RequiredIcon>*</RequiredIcon><small> - pole wymagane</small>
                    </FlexBox>
                    <FlexBox textAlign="left" margin="1rem 0 0 0">
                        <small>Informujemy, że zgoda może zostać cofnięta w dowolnym momencie, bez wpływu na zgodność z prawem przetwarzania danych osobowych, które nastąpiło przed cofnięciem zgody. Cofnięcie zgody może nastąpić przez wysłanie wiadomości na nasz adres e-mail: info@vibe.pl</small>
                    </FlexBox>

                </EmailRow>
                <Footer>
                    <Button
                        type="submit"
                        RightIcon={<NextArrowIcon />}
                        disabled={
                            !emailWatcher
                            || errors["email"]
                            || !agreementWatcher
                            || isDisableButton
                        }
                    >
                        {isMobile ? (
                            "Dalej"
                        ) : (
                            <span style={{ whiteSpace: "nowrap" }}>
                                Wypełnij wniosek
                            </span>
                        )}
                    </Button>
                </Footer>
            </Form>
            <ReCaptchaContainer>
                <GoogleReCaptcha onVerify={setToken} />
            </ReCaptchaContainer>
        </GoogleReCaptchaProvider>
    );
};

export default EmailConfirm;

const Form = styled.form`
    display:flex;
    flex-direction: column;
    gap: 40px;
`
