import { axios } from "../axios";
import { store } from "../store";

const Step1Service = {
    saveStep1: (transationId) => (data) => {
        const token = store.getState().transaction.transaction.token;

        return axios
            .put(`/transaction/save/step/1/${transationId}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res.data;
            });
    },
};

export default Step1Service;
