// eslint-disable-next-line no-unused-vars
import { useSelector } from "react-redux";
import { axios } from "../axios";
// eslint-disable-next-line no-unused-vars
import { selectTransactionToken } from "../features/transactionSlice";
import { store } from "../store";

const Step2Service = {
    checkNip: (nip) => () => {
        const token = store.getState().transaction.transaction.token;
        return axios
            .get(`/transaction/save/step/2/check-nip/employer/${nip}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                return res.data;
            });
    },
    saveContractOfEmployment: (transation_id) => (data) => {
        const token = store.getState().transaction.transaction.token;
        return axios
            .put(
                `/transaction/save/step/2/natural-person/contract-of-employment/${transation_id}`,
                {
                    ...data,
                    c30: {
                        c2: "CONTRACT_OF_EMPLOYMENT",
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    savePensionOrRent: (transation_id) => () => {
        const token = store.getState().transaction.transaction.token;
        return axios
            .put(
                `/transaction/save/step/2/natural-person/pension-or-rent/${transation_id}`,
                {
                    c30: {
                        c2: "PENSION_OR_RENT",
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    saveDividends: (transation_id) => (data) => {
        const token = store.getState().transaction.transaction.token;
        return axios
            .put(
                `/transaction/save/step/2/natural-person/dividents/${transation_id}`,
                {
                    ...data,
                    c30: {
                        c2: "DIVIDENDS",
                    },
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    saveLegalPerson: (transation_id) => (data) => {
        const token = store.getState().transaction.transaction.token;
        return axios
            .put(
                `/transaction/save/step/2/legal-person/${transation_id}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    saveIndividualEntrepreneur: (transation_id) => (data) => {
        const token = store.getState().transaction.transaction.token;
        return axios
            .put(
                `/transaction/save/step/2/individual-entrepreneur/${transation_id}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
    saveIndividualEntrepreneurIncome: (transation_id) => (data) => {
        const token = store.getState().transaction.transaction.token;
        return axios
            .put(
                `/transaction/save/step/2/individual-entrepreneur/income/${transation_id}`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((res) => {
                return res.data;
            });
    },
};

export default Step2Service;
