import { useSelector } from "react-redux";
import { selectMobile } from "../../../features/appSlice";
import DesktopContractData from "./DesktopContractData";
import MobileContractData from "./MobileContractData";

const ContractData = ({ navProps }) => {
    const isMobile = useSelector(selectMobile);

    if (isMobile) {
        return <MobileContractData navProps={navProps} />;
    }
    return <DesktopContractData navProps={navProps} />;
};

export default ContractData;
